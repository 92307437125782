import React from "react"
import {Link} from "gatsby"
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/home.scss"
import "../styles/custom.scss"
import HomeBannerBg from "../images/front-image.png";
import TechSectionBg from "../images/tech_bg.png";
import singleMan from "../images/decko-koji-obecava.png";

const IndexPage = (props) => (
            <Layout>
                <SEO title="Software development company – S7 Code&Design" keywords={[`S7 Design`, `software`, `development`, `company`]}
                     description="S7 Code&Design is company specializes in custom software and website
development using open source technologies and products that are built on top of it. As a
modern and serious company that keeps up-to-date with the latest technologies, we can
respond to most demanding market needs with our knowledge and experience." />

                <div className="home-banner" style={{ backgroundImage: `url(${HomeBannerBg})` }}>
                    <div className="container">
                        <div className="row banner-inner">
                            <div className="col-md-10 col-12 banner-heading">
                                <ul>
                                    <li>Design</li>
                                    <li>Technologies</li>
                                    <li>Future</li>
                                </ul>
                                <h1>Creativity & knowledge</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-main--content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="slogan col-lg-8 col-12">
                                <p>We are a software company with bright ideas and courageous hearts
                                    who gives you inspiration and changes. S7 is a place where great ideas come from
                                    creative and dedicated people who are operating together.</p>
                            </div>
                        </div>
                        <div className="w-100"></div>
                        <h2 className="heading text-center">What we do</h2>
                        <ul className="row what-we-do">
                            <li className="col-md-6 col-12 what-we-do--inner_with-margin">
                                <Img fluid={props.data.outsourcing.childImageSharp.fluid} />
                                    <h3>Outsourcing</h3>
                            </li>
                            <li className="col-md-6 col-12">
                                <Img fluid={props.data.mob_apps.childImageSharp.fluid} />
                                    <h3>Mobile<br/>applications</h3>
                            </li>
                            <li className="col-md-6 col-12 what-we-do--inner_with-margin">
                                <Img fluid={props.data.web_dev.childImageSharp.fluid} />
                                    <h3>Website <br/>development</h3>
                            </li>
                            <li className="col-md-6 col-12">
                                <Img fluid={props.data.addit_serv.childImageSharp.fluid} />
                                    <h3>Additional IT services</h3>
                            </li>
                            <li className="col-md-6 col-12 what-we-do--inner_with-margin">
                                <Img fluid={props.data.ar_vr.childImageSharp.fluid} />
                                    <h3>AR/VR development</h3>
                            </li>
                        </ul>
                    </div>
                    <div className="w-100"></div>
                    <h2 className=" heading text-center">How we do</h2>
                    <div className="how-we-do">
                        <div className="row top-line--blocks">
                            <div className="col-md-6 col-12 no-padding text-block">
                                <div className="row">
                                    <div className="offset-lg-2 offset-1 col-lg-8 col-10 ">
                                        <h3 className="mb-2">We want our clients to know
                                            what they’re receiving</h3>
                                        <p> Having 7 years of experience and many projects in our portfolio,
                                            we are a technology-driven team of developers who provide services
                                            that cover the majority of business
                                            needs for successful product and system development.
                                            We offer a full-service approach to product development from design
                                            to frontend and backend development, through to maintenance and support.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 no-padding">
                                <Img fluid={props.data.how_we_do_1.childImageSharp.fluid} />
                            </div>
                        </div>
                        <div className="row middle-line--blocks">
                            <div className="col-md-6 col-12 no-padding">
                                <Img fluid={props.data.hwd_text_image.childImageSharp.fluid} />
                            </div>
                            <div className="col-md-6 col-12 no-padding text-block">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8 col-10">
                                        <p>“Our approach to work begins with our attitude towards the client’s ideas.
                                            We help them build solutions, unique identity and a secure future.”</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row bottom-line--blocks">
                            <div className="col-md-6 col-12 no-padding text-block--left">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8 col-10">
                                        <p>Development means endless work and improvement of the product.
                                            We use Agile development methodology who allows us to save time,
                                            speed up the implementation process and improve the quality of a
                                            product's usability. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 no-padding text-block--right">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <h3>How we develop products</h3>
                                        <ul className="process-line">
                                            <li>
                                                <span>1</span>
                                                <h4>Design</h4>
                                                <p>This is the phase where you and the development team determine what
                                                    your software needs, how it will look and also recognizes all the
                                                    functional and non-functional requirements of the project.
                                                    It is the best phase to correct your mistakes in case you find any.
                                                </p>
                                            </li>
                                            <li>
                                                <span>2</span>
                                                <h4>Development</h4>
                                                <p>Here is when the software product takes shape. The developers start
                                                    to build the entire system by writing code and connect it with
                                                    the database of the product. This phase takes a lot of time and
                                                    needs constant communication with the client about the project’s
                                                    progress. </p>
                                            </li>
                                            <li>
                                                <span>3</span>
                                                <h4>Quality assurance</h4>
                                                <p>We use a systematic way of evaluating the quality of the product
                                                    so that it meets the client's expectations. QA is not only testing
                                                    but also measuring the quality of processes, improving processes,
                                                    and preventing defects.</p>
                                            </li>
                                            <li>
                                                <span>...</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="tech-section">
                        <div className="balloons-wrapper">
                            <div id="balloons">
                                <div className="balloons bsmall BC-1">
                                    <Img fluid={props.data.anotherballoon.childImageSharp.fluid} />
                                </div>
                                <div className="balloons bsmall BC-2">
                                    <Img fluid={props.data.balloon.childImageSharp.fluid} />
                                </div>
                                <div className="balloons blarge BC-3">
                                    <Img fluid={props.data.anotherballoon.childImageSharp.fluid} />
                                </div>
                                <div className="balloons blarge BC-4">
                                    <Img fluid={props.data.balloon.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>
                        <div className="tech-we-use" style={{ backgroundImage: `url(${TechSectionBg})` }}>
                            <div className="container">
                                <div className="row justify-content-center tech-text">
                                    <div className="col-md-10 col-12">
                                        <h2 className="heading text-center">Technology we use</h2>
                                        <p>Within the years of working with diverse companies worldwide for a variety
                                            of business sectors, S7 attained valuable experience and solid technological
                                            expertise to start a project of any scale and scope.
                                            We utilize all available skills, resources, and technologies to ensure
                                            that these solutions deliver expected business value. </p>
                                        <div className="row justify-content-center">
                                            <div className="col tech-button">
                                                {/*<a href="#">Our tech stack<span></span></a>*/}
                                                <Link to="/technology" >Our tech stack<span></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-100"></div>
                    <h2 className="heading text-center">Join us</h2>
                    <div className="join-us">
                        <div className="row top-line--blocks">
                            <div className="col-md-6 col-12 no-padding text-block">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-md-12">
                                        <h3>Let’s build great things together</h3>
                                        <p>We love building things with great people, and we need extraordinary people
                                            on board to build something great. Together we constantly learn,
                                            experimenting, and growing as an individual and as a part of a
                                            close-knit team. Ready to make an impact?</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 no-padding">
                                <img className="single-man" src={singleMan}/>
                                <Img fluid={props.data.join_us_1.childImageSharp.fluid} />
                            </div>
                        </div>
                        <div className="row bottom-line--blocks">
                            <div className="col-md-6 col-12 no-padding">
                                <Img fluid={props.data.join_us_2.childImageSharp.fluid} />
                            </div>
                            <div className="col-md-6 col-12 no-padding text-block">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-md-12">
                                        <h3>Grow your career with us</h3>
                                        <p>We are a team of passionate and devoted IT professionals who are working on
                                            innovative projects every day. You can do so as well!
                                            If you desire to build your IT career, S7 is a great opportunity for you!
                                            We are constantly growing and looking to expand to new business areas.
                                            Come, meet and join the team!</p>
                                        <div className="join-button">
                                            <Link to="/career">Career<span></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
)
export default IndexPage


export const pageQuery = graphql`
  query {
    outsourcing: file(relativePath: { eq: "outsourcing.png" }) {
      ...fluidImage
    }
    mob_apps: file(relativePath: { eq: "mob_apps.png" }) {
      ...fluidImage
    }
    web_dev: file(relativePath: { eq: "web_dev.png" }) {
      ...fluidImage
    }
    addit_serv: file(relativePath: { eq: "addit_serv.png" }) {
      ...fluidImage
    }
    ar_vr: file(relativePath: { eq: "ar_vr.png" }) {
      ...fluidImage
    }
    how_we_do_1: file(relativePath: { eq: "how_we_do.png" }) {
      ...fluidImage
    }
    
   hwd_text_image: file(relativePath: { eq: "hwd_text.png" }) {
      ...fluidImage
    }
    join_us_1: file(relativePath: { eq: "join_us.png" }) {
      ...fluidImage
    }
    join_us_2: file(relativePath: { eq: "join_us_2.png" }) {
      ...fluidImage
    }
    balloon: file(relativePath: { eq: "yellow.png" }) {
      ...fluidImage
    }
    anotherballoon: file(relativePath: { eq: "balloon.png" }) {
      ...fluidImage
    }
  }
`